var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card mt-2"
  }, [_c('div', {
    staticClass: "card-header text-center bg-primary"
  }, [_c('h3', [_vm._v("Edit Premium AX")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('h5', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.PremiumAxPath.name
      }
    }
  }, [_c('span', {
    staticClass: "badge badge-primary"
  }, [_c('i', {
    staticClass: "fa fa-rotate-left mr-1"
  }), _vm._v("Back")])])], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-3"
  }), _c('div', {
    staticClass: "col-sm-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Item : ")]), _c('multiselect', {
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "select item...",
      "open-direction": "bottom",
      "options": _vm.itemPremiumSearchOptions,
      "searchable": true,
      "loading": _vm.isLoadingItemPremiumSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false
    },
    on: {
      "search-change": _vm.itemPremiumFind,
      "select": _vm.selectItem
    },
    model: {
      value: _vm.itemPremium,
      callback: function ($$v) {
        _vm.itemPremium = $$v;
      },
      expression: "itemPremium"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("Name"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" :")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.name,
      expression: "name"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "EBOOK:Filosofi Teras"
    },
    domProps: {
      "value": _vm.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.name = $event.target.value;
      }
    }
  }), _vm.itemPremium ? _c('small', {
    staticClass: "font-weight-bold"
  }, [_vm._v("ITEM ID : " + _vm._s(_vm.item_id))]) : _vm._e()]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("Base Price"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" :")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.basePrice,
      expression: "basePrice"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "placeholder": "49000"
    },
    domProps: {
      "value": _vm.basePrice
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.basePrice = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "organization"
    }
  }, [_vm._v("Premium ID AX"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" :")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.premium_idax,
      expression: "premium_idax"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "placeholder": "528196376"
    },
    domProps: {
      "value": _vm.premium_idax
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.premium_idax = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Vendor : ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.vendor,
      expression: "vendor"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "Gramedia Pustaka Utama"
    },
    domProps: {
      "value": _vm.vendor
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.vendor = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Vendor ID AX : ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.vendor_id_ax,
      expression: "vendor_id_ax"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "V-000005276"
    },
    domProps: {
      "value": _vm.vendor_id_ax
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.vendor_id_ax = $event.target.value;
      }
    }
  })]), _c('br'), _c('br'), _c('button', {
    staticClass: "btn btn-primary btn-block",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function ($event) {
        return _vm.editPremiumAx();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pencil"
  }), _vm._v(" Update Premium AX ")])]), _c('div', {
    staticClass: "col-sm-3"
  })])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }