<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="card mt-2">
      <div class="card-header text-center bg-primary">
        <h3>Edit Premium AX</h3>
      </div>
      <div class="card-body">
        <h5>
          <router-link
            :to="{
              name: PremiumAxPath.name,
            }"
          >
            <span class="badge badge-primary"
              ><i class="fa fa-rotate-left mr-1"></i>Back</span
            >
          </router-link>
        </h5>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <div class="form-group">
              <label>Item : </label>
              <multiselect
                v-model="itemPremium"
                label="name"
                track-by="name"
                placeholder="select item..."
                open-direction="bottom"
                :options="itemPremiumSearchOptions"
                :searchable="true"
                :loading="isLoadingItemPremiumSearch"
                :close-on-select="true"
                :options-limit="10"
                :multiple="false"
                @search-change="itemPremiumFind"
                @select="selectItem"
              >
              </multiselect>
            </div>

            <div class="form-group">
              <label for="organization"
                >Name<span class="text-danger">*</span> :</label
              >
              <input
                v-model="name"
                type="text"
                class="form-control"
                placeholder="EBOOK:Filosofi Teras"
              />
              <small v-if="itemPremium" class="font-weight-bold"
                >ITEM ID : {{ item_id }}</small
              >
            </div>

            <div class="form-group">
              <label for="organization"
                >Base Price<span class="text-danger">*</span> :</label
              >
              <input
                v-model="basePrice"
                type="number"
                class="form-control"
                placeholder="49000"
              />
            </div>

            <div class="form-group">
              <label for="organization"
                >Premium ID AX<span class="text-danger">*</span> :</label
              >
              <input
                v-model="premium_idax"
                type="number"
                class="form-control"
                placeholder="528196376"
              />
            </div>

            <div class="form-group">
              <label>Vendor : </label>
              <input
                v-model="vendor"
                type="text"
                class="form-control"
                placeholder="Gramedia Pustaka Utama"
              />
            </div>

            <div class="form-group">
              <label>Vendor ID AX : </label>
              <input
                v-model="vendor_id_ax"
                type="text"
                class="form-control"
                placeholder="V-000005276"
              />
            </div>

            <br /><br />
            <button
              type="submit"
              class="btn btn-primary btn-block"
              @click="editPremiumAx()"
            >
              <i class="fa fa-pencil"></i>
              Update Premium AX
            </button>
          </div>
          <div class="col-sm-3"></div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { PremiumAxPath } from '../../router/content';

export default {
  name: 'UpdatePremiumAX',
  data() {
    return {
      PremiumAxPath,
      itemPremium: [],
      itemPremiumSearchOptions: [],
      name: null,
      basePrice: null,
      premium_idax: null,
      vendor: null,
      vendor_id_ax: null,
      item_id: null,
    };
  },
  watch: {
    successMessage: function() {
      if (!this.successMessage) return;
      this.alertMsg(this.successMessage, 'success');
      this.$router.push('/ax/premium-ax');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.alertMsg(this.errorMessage, 'error');
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something went wrong !', 'error');
    },
    item: function() {
      if (!this.item) return;
      this.getDataPremiumAx();
    },
    itemsPremium: function() {
      if (!this.itemsPremium) return;
      this.itemPremiumSearchOptions = this.itemsPremium;
    },

    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Edit Premium AX | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  created: function() {
    const id = this.$route.params.id;
    this.getPremiumAxById({ id });
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.ItemSoax.isLoading,
      isError: (state) => state.ItemSoax.isError,
      successMessage: (state) => state.ItemSoax.successMessage,
      errorMessage: (state) => state.ItemSoax.errorMessage,
      item: (state) => state.ItemSoax.item,
      itemsPremium: (state) => state.ebooks.items,
      isLoadingItemPremiumSearch: (state) => state.ebooks.isLoading,
    }),
  },
  methods: {
    ...mapActions('ItemSoax', ['getPremiumAxById', 'updatePremiumAx']),
    ...mapActions('ebooks', ['searchBooks']),

    getDataPremiumAx() {
      const item = this.item;
      this.name = item.name;
      this.basePrice = item.base_price;
      this.premium_idax = item.premium_idax;
      this.vendor = item.vendor;
      this.vendor_id_ax = item.vendor_id_ax;
      this.item_id = item.item_id;
    },

    itemPremiumFind(query) {
      if (!query) return;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.actionSearchEbooks(query);
      }, 600);
    },

    actionSearchEbooks(keyword) {
      const payload = {
        q: keyword ?? '',
        page: 1,
        limit: 50,
      };
      this.searchBooks(payload);
    },

    selectItem(data) {
      this.item_id = data?.id;
      this.name = data?.name;
      this.vendor = data?.vendor;
      this.vendor_id_ax = null;
    },

    editPremiumAx() {
      const payload = {
        id: this.$route.params.id,
        name: this.name,
        basePrice: this.basePrice,
        premium_idax: this.premium_idax,
        vendorName: this.vendor,
        vendor_idax: this.vendor_id_ax,
        item_id: this.item_id,
      };
      this.updatePremiumAx(payload);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
